import http from "../request/https.js"
//例子：比如说现在banner这个接口不需要传token,就在函数中传入第二个参数为true，如果需要传token则不需要传第二个参数
// export function banner(data,true){
//     return http.get("banner",data)
// }


export function banner(data){
    return http.get("banner",data)
}

//投诉列表
export function complaint_index(data){
    return http.get("complaint_index",data,true)
}

//投诉详情
export function complaint_details(data){
    return http.get("complaint_details",data)
}
//热门评论
export function comment_list(data){
    return http.get("comment_list",data)
}
//商家投诉统计
export function Business_complaints(data){
    return http.get("manufacturer",data)
}
//投诉评论留言
export function instantly_message(data){
    return http.post("instantly_message",data)
}

//投诉对象列表
export function Complaint_object_list(data){
    return http.get("business_index",data)
}
// 添加投诉对象
export function Add_Complaint_object_list(data){
    return http.post("business_create",data)
}

//投诉申请创建
export function Complaint_application(data){
    return http.post("create",data)
}

//点赞详情页接口
export function give_up(data){
    return http.post("userComplaint_like",data)
}
//点赞收藏状态展示接口
export function give_ups(data){
    return http.get("like_status",data)
}
//收藏详情页接口
export function Collection(data){
    return http.post("userComplaint_collection",data)
}
//品牌-行业分类
export function HangYe_type(data){
    return http.get("hangYe_type",data)
}
//企业认领
export function Attestation(data){
    return http.post("attestation",data)
}

//个人投诉列表
export function ComplaintUser_info(data){
    return http.get("complaintUser_info",data)
}
//投诉撤销和确认完成
export function complaint_revoke(data){
    return http.post("complaint_revoke",data)
}
//重新发起投诉
export function relaunch(data){
    return http.post("relaunch",data)
}
//用户满意度评价
export function userComplaint_evaluate(data){
    return http.post("userComplaint_evaluate",data)
}
//补充投诉
export function complaint_update(data){
    return http.post("complaint_update",data)
}
//收藏列表
export function collection_list(data){
    return http.get("collection_list",data)
}		
//投诉进展
export function Progress(data){
    return http.get("progress",data)
}	
//进展发表内容
export function Progress_reply(data){
    return http.post("progress_reply",data)
}
//回复留言
export function news_save(data){
    return http.post("news_save",data)
}
//商家统计列表
export function manufacturer_list(data){
    return http.get("manufacturer_list",data)
}
//企业入驻
export function attestation_one(data){
    return http.post("attestation_one",data)
}
//行业分类投诉数据
export function industry_type(data){
    return http.get("industry_type",data)
}
//通知列表
export function notice_list(data){
    return http.get("notice_list",data)
}
//消息通知
export function news_list(data){
    return http.get("news_list",data)
}
//企业个人中心数据
export function attestation_user_list(data){
    return http.get("attestation_user_list",data)
}
//企业认证详情
export function attestation_info(data){
    return http.get("attestation_info",data)
}
//企业修改信息头部
export function attestation_update(data){
    return http.post("attestation_update",data)
}
//企业修改信息中部
export function attestation_update_one(data){
    return http.post("attestation_update_one",data)
}
//企业修改信息底部
export function attestation_update_two(data){
    return http.post("attestation_update_two",data)
}
//企业品牌账号修改
export function attestation_phone(data){
    return http.post("attestation_phone",data)
}
//是否有留言，消息 统计
export function message_count(data){
    return http.get("message_count",data)
}
//个人中心首页统计--pc端
export function complaint_count(data){
    return http.get("complaint_count",data)
}
//个人中心首页消息--pc端
export function complaint_msg(data){
    return http.get("complaint_msg",data)
}
//关于我们 --pc端
export function about(data){
    return http.get("about",data)
}
//企业消息数量
export function message_count_one(data){
    return http.get("message_count_one",data)
}
//用户评论点赞
export function userComment_like(data){
    return http.post("userComment_like",data)
}
//评论点赞状态
export function userCommentLike_status(data){
    return http.get("userCommentLike_status",data)
}
//广告
export function advert(data){
    return http.get("advert",data,true)
}
export default{

}