import request from "./request"

const http = {
    get(url,params,type){
        const config = {
            method:'get',
            url
        }
        if(params){
            config.params = params
        }
        // console.log("url:",url)
        return request(config,type)
    },
    post(url,params,type){
        const config = {
            method: 'post',
            url
        }
        console.log("url:",url)
        if(params) config.data = params
        return request(config,type)
    }
}

export default http