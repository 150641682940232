<template>
  <div>
    <div class="header">
      <div class="header_bx">
        <div class="header_bx_left">
          <img class="logo" @click="G_index" src="../assets/img/y_logo.png" alt="" />
          <!-- <a href="http://www.315chn.com/" target="_blank"><img class="logo1" src="../assets/img/logo1.png" alt=""></a> -->
        </div>
        <div class="header_bx_center" v-if="this.issearch ==true">
          <ul class="nav">
            <li v-for="(item,index) in guides" :key="index"> 
              <router-link :to="item.link" class="nav-a">{{item.name}}</router-link>
              <a href="https://top.qudong.com/" target="_blank" style="font-size:18px;padding:25px;" v-if="item.id==3">榜单</a>
              <a href="https://www.qudong.com/" target="_blank"  style="font-size:18px;padding:25px;" v-if="item.id==3">媒体</a>
            </li>
          </ul>
        </div>
        <div class="search" v-if="this.issearch ==false">
          <img class="search_close" @click="Close_search" src="../assets/img/close.png" alt="">
          <input class="search_ipt" @keyup.enter="result_search" placeholder="搜一搜" v-model="iptsearch" type="text" />
          <img @click="result_search"  class="magnifier" src="../assets/img/search_icon.png" alt="">
        </div>
        <div class="header_bx_right">
          <img v-if="this.issearch ==true" @click="G_Search()" src="../assets/img/search_icon.png" alt="">
          <div v-if="tokens ==null"><a style="cursor: pointer;" @click="G_Login">登录</a>/<span @click="G_register" style="cursor: pointer;">注册</span></div>
          <div v-if="this.tokens != null">
            <el-badge v-if="is_brand_user==0" :value="msg_count>0?msg_count:''" @click.native="Go_Message" class="item">
              <i class="el-icon-message" style="font-size:24px"></i>
            </el-badge>
            <el-badge v-if="is_brand_user==1" :value="msg_counts>0?msg_counts:''" @click.native="Go_Message" class="item">
              <i class="el-icon-message" style="font-size:24px"></i>
            </el-badge>
          </div>
          <div id="user" v-if="this.tokens != null">
            <el-dropdown  @command="handleCommand">
              <span class="el-dropdown-link">
                <img class="user_img" :src="headimgurl" alt="">
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="is_brand_user==0" command="a">个人中心</el-dropdown-item>
                <el-dropdown-item v-if="is_brand_user==1" command="c">企业中心</el-dropdown-item>
                <el-dropdown-item command="b">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {message_count,message_count_one} from "../api/api";
export default {
  name: "Header",
  // props:["propszi"],
  data() {
    return {
      is_brand_user:localStorage.getItem("is_brand_user"),
      guides: [
        {
          id: 0,
          name: "首页",
          link: "/index",
        },
        {
          id: 1,
          name: "热点",
          link: "/Hot",
        },
        {
          id: 3,
          name: "品牌",
          link: "/Report",
        },
        // {
        //   id: 4,
        //   name: "榜单",
        //   link: "https://www.qudong.com/",
        // },
        // {
        //   id: 5,
        //   name: "媒体",
        //   link: "download",
        // },
      ],
      issearch: true,
      iptsearch: "",
      tokens: "", //token
      headimgurl: "", //用户图片
      msg_count:'',   //个人消息总数
      msg_counts:'',    //企业消息总数
    };
  },

  created() {
    let token = localStorage.getItem("token");
    let headimgurl = localStorage.getItem("headimgurl");
    this.tokens = token;
    this.headimgurl = headimgurl;
    // 消息总数
    if(this.tokens){
      message_count({ }).then((res) => {
        //console.log(res)
        this.msg_count=res.data.msg_count;
      });
      message_count_one({ }).then((res) => {
        // console.log(res)
        this.msg_counts=res.data.msg_count;
      });
    }
  },

  methods: {
    // 去消息页面
    Go_Message(){
      let is_brand_user=localStorage.getItem('is_brand_user');
      //console.log(is_brand_user);
      if(is_brand_user ==1){
         this.$router.push({ path: "/EnterpriseCenter/NoticeNotice" });
      }else{
        this.$router.push({ path: "/PersonalCenter/NoticeNotice" });
      }
    },
    G_Search() {
      this.issearch = false;
    },
    Close_search() {
      this.issearch = true;
    },
    result_search() {
      //console.log(this.iptsearch);
      this.$router.push({path: '/Search',query:{ iptval:this.iptsearch}});
    },
    G_index() {
      this.$router.push({ path: "/" });
    },
    G_Login(){
      this.$router.push({ path: "/login" });
    },
    G_register(){
      this.$router.push({path:"/register"});
    },
    success(m) {
      layer.msg(m, { icon: 1 });
    },
    error(m) {
      layer.msg(m, { icon: 0 });
    },
    // fun1(){
    //    this.$parent.fun()
    // }
    handleCommand(command) {
      //console.log(command);
      if(command == "a"){
       this.$router.push({ name: "PersonalCenter" });
      }
      if(command == "c"){
       this.$router.push({ path: "/EnterpriseCenter" });
      }
      if (command == "b") {
        this.$confirm("确认是否退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            setTimeout(() => {
              localStorage.clear(); //
              location.href = "/";
              this.$message({
                type: "success",
                message: "退出成功!",
              });
            }, 300);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 75px;
  background: #fff;
}
.header_bx {
  width: 1200px;
  height: 72px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 157px;
  height: 39px;
  cursor: pointer;
}
.logo1 {
  margin-left: 15px;
  width: 254px;
  height: 39px;
}
.header_bx_left {
  /* width: 200px; */
  height: 75px;
  display: flex;
  align-items: center;
}
.header_bx_center {
  margin-left: 100px;
}
.nav {
  height: 75px;
  line-height: 75px;
  display: flex;
}
.nav-a {
  padding: 25px;
  font-size: 18px;
  color: #333333;
}
.router-link-active {
  background-color: #1F72D9;
  font-size: larger;
  color: aliceblue;
}
.header_bx_right {
  display: flex;
  align-items: center;
}
.header_bx_right img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
}
.search {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search_close {
  width: 15px;
  height: 15px;
}
.search_ipt {
  width: 430px;
  border: none;
  padding-left: 10px;
}
.magnifier {
  width: 20px;
  height: 20px;
}
#user .user_img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-left: 30px;
}
.item{
  cursor: pointer;
}
</style>