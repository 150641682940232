<template>
  <div id="app">
    <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
import queryString from "query-string";
export default {
  name: "App",
  created() {
    var query = queryString.parse(location.search);
    console.log(query)
    if (Object.keys(query).indexOf("shareUrl") > -1) {
      console.log("shareurl:", query.shareUrl);
      this.$router.push(query.shareUrl);
    }
    this.IsToken();
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    // 登录过期
    IsToken() {
      var that = this;
      var data = localStorage.getItem("gettime");
      if (data != null) {
        let newdata = Number(data) + 7 * 24 * 60 * 60 * 1000;
        if (data != "" && newdata < new Date().getTime()) {
          localStorage.clear();
          this.$alert("您的登录时间已失效,请您重新登录", "提示!", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$message({
                type: "info",
                message: "用户已确认",
              });
              that.reload();
            },
          });
        } else {
        }
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="stylus"></style>
