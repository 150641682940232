<template>
  <div>
  <ul class="nav nav-pills">
 <li role="presentation" v-for="(item,index) in guides" :key="index"
  :class="item.id==discovercurrent?'active':''"
 >
  <router-link :to="item.link" exact>{{item.name}}</router-link>
 </li>
 </ul>
      <router-view></router-view>
  </div>
</template>

<script>
// import { complaint_index } from "../api/api.js";
import Axios from "axios"
import Swiper from "swiper";
export default {
  name: "Main_Left",
  data() {
    return {
      List: [],
      actives: 0,
      page: 1,
      hidden: false,
      sectionData: [],
      discovercurrent:0,
      guides:[
 {
  id:0,
  name:'热门投诉',
  link:'/index/Hot'
 },
 {
  id:1,
  name:'汽车',
  link:'/index/automobile'
 },
  {
  id:2,
  name:'电商',
  link:'/index/retailers'
 },
  {
  id:3,
  name:'共享服务',
  link:'/index/SharedServices'
 },
  {
  id:4,
  name:'旅游出行',
  link:'/index/Travel'
 },
  {
  id:5,
  name:'视听娱乐',
  link:'/index/AudioVisual'
 },
  {
  id:6,
  name:'教育培训',
  link:'/index/EducationTraining'
 },
  {
  id:7,
  name:'消费电子',
  link:'/index/ConsumerElectronics'
 },
  {
  id:8,
  name:'其他',
  link:'/index/Other'
 },

 ]
    };
  },

  methods: {

    // complaint_index() {
    // let tokens=localStorage.getItem('token');
    // if(tokens){
    //   complaint_index({ type: 0 }).then((res) => {
    //     ////console.log(res.data.data);
    //     this.List = res.data.data.data;
    //   });
    // }else{
    //   Axios({
    //     method: "get",
    //     url: "https://tsadmin.qudong.com/api/complaint_index",
    //    params: {
    //       type: 0
    //     },
    //   }).then((res) => {
    //       ////console.log(res.data.data);
    //     this.List = res.data.data.data;
    //   });
    // }
    // },
    // prev() {
    //   this.$refs.swiper.$swiper.slidePrev();
    // },
    // next() {
    //   this.$refs.swiper.$swiper.slideNext();
    // },
    // Swiper() {
    //   new Swiper(".czp", {
    //     // direction: 'vertical', // 垂直切换选项
    //     slidesPerView: 9,
    //     // 如果需要分页器
    //     pagination: {
    //       el: ".swiper-pagination",
    //     },



    //     // 如果需要滚动条
    //     scrollbar: {
    //       el: ".swiper-scrollbar",
    //     },
    //   });
    // },
    tab(index) {
      ////console.log(index);
      this.actives = index;
      if (index == 0) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.push({ path: "/category/" + index });
      }
    },
    // // 加载更多
    // Load_more() {
    //   this.page++;
    //   complaint_index({ type: 0, page: this.page }).then((res) => {
    //     ////console.log(res.data.data);
    //     if (this.page == 1) {
    //       this.sectionData = res.data.data;
    //     } else {
    //       let arr = this.List.concat(res.data.data);
    //       ////console.log(arr);
    //     }

    //     if (res.data.data == "") {
    //       this.hidden = true;
    //     }
    //     //  this.List= this.sectionData
    //   });
    // },
  },
  mounted() {
    let that = this;
  },
};
</script>

<style scoped>
.Bottom_list_bx_left {
  width: 900px;
  background: #fff;
}
.Bottom_list_bx_right {
  width: 280px;
}
.czp {
  width: 96%;
  height: 50px;
  line-height: 50px;
  padding-left: 35px;
}
.swiper-button-prev {
  background-size: 25px 25px;
  font-size: 30px;
  background: #fff;
  width: 33px;
  height: 53px;
  left: -3px;
  top: 23px;
  color: #1f72d9;
}
.swiper-button-next {
  background-size: 25px 25px;
  font-size: 30px;
  background: #fff;
  width: 33px;
  height: 53px;
  right: -2px;
  top: 23px;
  color: #1f72d9;
}
.Display_list {
  width: 100%;
}
.Display_list_box {
  width: 100%;
  padding-bottom:25px;

  cursor: pointer;
}
.Display_list_box_top:nth-child(1) {
  border-top: 1px solid #e5e5e5;
}
.Display_list_box_top {
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: space-between;
}
.Display_list_box_top_left {
  font-size: 20px;
}
.Display_list_box_top_right {
  background: #1f72d9;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fff;
}
.Display_list_box_top_right1 {
  background: orange;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fff;
}
.Display_list_box_center {
  padding: 0 20px;

  margin-top: 10px;
}
.Display_list_box_center_top {
  display: flex;
}
.Display_list_box_center_left {
  color: #1f72d9;
  width: 77px;
  margin-right: 5px;
}
.Display_list_box_center_right {
  width: 650px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Display_list_box_bottom {
  display: flex;
  padding: 0 20px;
  margin-top: 10px;
}
.Display_list_box_bottom_left {
  width: 50%;
  display: flex;
  align-items: center;
}

.Display_list_box_bottom_left img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}
.Display_list_box_bottom_left .name {
  margin-right: 30px;
}
.Display_list_box_bottom_left .data {
  color: #999999;
}
.Display_list_box_bottom_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Display_list_box_bottom_right img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-left: 30px;
}
.swiper-slide {
  cursor: pointer;
}
.swiper-slide:nth-child(1) {
  width: 60px !important;
}
.active {
  color: #2397ee;
}

/* 加载更多 */
.Load_more {
  width: 100%;
  height: 50px;
  text-align: center;
  color: #1f72d9;
  line-height: 50px;
  cursor: pointer;
}
.Load_mores {
  width: 100%;
  height: 50px;
  text-align: center;
  color: black;
  line-height: 50px;
  cursor: pointer;
}


/* 二级导航 */
.nav{
  width:900px;
  height:48px;
  background:#fff;
  display:flex;
  line-height:48px;
font-size: 18px;
justify-content:space-evenly;
}
.nav-pills li{
  margin-right:20px;
}
.router-link-active {
border-bottom:1px solid #1F72D9;
padding-bottom:12px;
color:#1F72D9;
font-size:18px;
font-weight: bold;
}
</style>