import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import reset_css from './assets/css/reset.css'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
import { Tab, Tabs,Uploader,Rate,List,Pagination,Swipe, SwipeItem} from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(Rate);
Vue.use(List);
Vue.use(Pagination);
Vue.use(Swipe);
Vue.use(SwipeItem);
// 轮播

// import Swiper from 'swiper' //  ES6 导入swiper js文件
import 'swiper/dist/css/swiper.min.css' // ES6 导入swiper css文件



// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import http from '@/api/api'; // 引入接口列表
Vue.prototype.$axios = axios;



Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
  // Vue每次点击跳转页面位置回恢复在顶部
   // chrome
  document.body.scrollTop = 0;
   // firefox
  document.documentElement.scrollTop = 0;
    // safari
  window.pageYOffset = 0;
  
  NProgress.start()
  
  next()
})
router.afterEach(() => {
  NProgress.done()
})


Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$http = http; // 将接口列表挂载到全局
new Vue({
  router,
  store,
  reset_css,
  render: h => h(App),
  mounted () {
    document.dispatchEvent( new Event( 'render-event' ))
    }
}).$mount('#app')
