import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import index from '../views/index.vue'






Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: '/index',
    name: 'index',
    redirect: '/index/Hot',
    component: index,
    children: [
      {
        path: 'Hot',
        component: resolve => require(['@/components/Ichildren/Hot'], resolve),
      },    //热门投诉
      {
        path: 'automobile',
        component: resolve => require(['@/components/Ichildren/automobile'], resolve),
      },    //汽车
      {
        path: 'retailers',
        component: resolve => require(['@/components/Ichildren/retailers'], resolve),
      },         //电商
      {
        path: 'SharedServices',
        component: resolve => require(['@/components/Ichildren/SharedServices'], resolve),
      },         //共享服务
      {
        path: 'Travel',
        component: resolve => require(['@/components/Ichildren/Travel'], resolve),
      },         //旅游出行
      {
        path: 'AudioVisual',
        component: resolve => require(['@/components/Ichildren/AudioVisual'], resolve),
      },         //视听娱乐
      {
        path: 'EducationTraining',
        component: resolve => require(['@/components/Ichildren/EducationTraining'], resolve),
      },         //教育培训
      {
        path: 'ConsumerElectronics',
        component: resolve => require(['@/components/Ichildren/ConsumerElectronics'], resolve),
      },         //消费电子
      {
        path: 'Other',
        component: resolve => require(['@/components/Ichildren/Other'], resolve),
      },         //其他
    ]
  },

  {
    path: '/cart',
    name: 'cart',
    component: resolve => require(['@/views/cart.vue'], resolve),
  },
  {
    path: '/Hot',
    name: 'Hot',
    component: resolve => require(['@/views/Hot.vue'], resolve),
  },
  {
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login.vue'], resolve),
  },
  {
    path: '/register',
    name: 'register',
    component: resolve => require(['@/views/register.vue'], resolve),
  },
  {
    path: '/category/:index',
    name: 'category',
    component: resolve => require(['@/views/category.vue'], resolve),
  },
  {
    path: '/Details/',
    name: 'Details',
    component: resolve => require(['@/views/Details.vue'], resolve),
  },
  {
    path: '/Report',
    name: 'Report',
    redirect: '/Report/HotBrands',
    component: resolve => require(['@/views/Report.vue'], resolve),
    children: [
      {
        path: 'HotBrands',
        component: resolve => require(['@/components/Pchildren/HotBrands'], resolve),
      },    //热门品牌
      {
        path: 'automobile',
        component: resolve => require(['@/components/Pchildren/automobile'], resolve),
      },    //汽车
      {
        path: 'retailers',
        component: resolve => require(['@/components/Pchildren/retailers'], resolve),
      },         //电商
      {
        path: 'SharedServices',
        component: resolve => require(['@/components/Pchildren/SharedServices'], resolve),
      },         //共享服务
      {
        path: 'Travel',
        component: resolve => require(['@/components/Pchildren/Travel'], resolve),
      },         //旅游出行
      {
        path: 'AudioVisual',
        component: resolve => require(['@/components/Pchildren/AudioVisual'], resolve),
      },         //视听娱乐
      {
        path: 'EducationTraining',
        component: resolve => require(['@/components/Pchildren/EducationTraining'], resolve),
      },         //教育培训
      {
        path: 'ConsumerElectronics',
        component: resolve => require(['@/components/Pchildren/ConsumerElectronics'], resolve),
      },         //消费电子
      {
        path: 'Other',
        component: resolve => require(['@/components/Pchildren/Other'], resolve),
      },         //其他
    ]
  },
  {
    path: '/RelatedComplaints',
    name: 'RelatedComplaints',
    component: resolve => require(['@/views/RelatedComplaints.vue'], resolve),
  },
  {
    path: '/BrandHome/:id',
    name: 'BrandHome',
    component: resolve => require(['@/views/BrandHome.vue'], resolve),
  },      //品牌主页
  {
    path: '/Complaints_Submit',
    name: 'Complaints_Submit',
    component: resolve => require(['@/views/Complaints_Submit.vue'], resolve),
  },
  {
    path: "/Entry_Enterprises",
    name: "Entry_Enterprises",
    component: resolve => require(['@/views/Entry_Enterprises.vue'], resolve),
  },    //企业认领
  {
    path: "/SettleIn",
    name: "SettleIn",
    component: resolve => require(['@/views/SettleIn.vue'], resolve),
  },  //企业入驻
  {
    path: "/PersonalCenter",
    name: "PersonalCenter",
    redirect: '/PersonalCenter/a',
    component: resolve => require(['@/views/PersonalCenter.vue'], resolve),
    children: [
      {
        path: 'a',
        component: resolve => require(['@/components/PersonalChildren/a'], resolve),
      },    //a
      {
        path: 'IWantComplain',
        component: resolve => require(['@/components/PersonalChildren/IWantComplain'], resolve),
      },    //我要投诉
      {
        path: 'MyComplaint',
        component: resolve => require(['@/components/PersonalChildren/MyComplaint'], resolve),
      },    //我的投诉
      {
        path: 'CDetails',
        component: resolve => require(['@/components/PersonalChildren/CDetails'], resolve),
      },    //详情
      {
        path: 'evaluate',
        component: resolve => require(['@/components/PersonalChildren/evaluate'], resolve),
      },    //评价
      {
        path: 'SupplementaryComplaint',
        component: resolve => require(['@/components/PersonalChildren/SupplementaryComplaint'], resolve),
      },    //补充投诉
      {
        path: 'MyCollection',
        component: resolve => require(['@/components/PersonalChildren/MyCollection'], resolve),
      },    //我的收藏CommentManagement.
      {
        path: 'CommentManagement',
        component: resolve => require(['@/components/PersonalChildren/CommentManagement'], resolve),
      },    //评论管理NoticeNotice
      {
        path: 'NoticeNotice',
        component: resolve => require(['@/components/PersonalChildren/NoticeNotice'], resolve),
      },    //通知公告
      {
        path: 'AccountInformation',
        component: resolve => require(['@/components/PersonalChildren/AccountInformation'], resolve),
      },    //账号信息
      {
        path: 'ChangeBind',
        component: resolve => require(['@/components/PersonalChildren/ChangeBind'], resolve),
      },    //绑定手机号
      {
        path: 'NewBind',
        component: resolve => require(['@/components/PersonalChildren/NewBind'], resolve),
      },    //绑定手机号2
      {
        path: 'ChangePassword',
        component: resolve => require(['@/components/PersonalChildren/ChangePassword'], resolve),
      },    //修改密码
      {
        path: 'Help',
        component: resolve => require(['@/components/PersonalChildren/Help'], resolve),
      },    //帮助反馈
    ]
  },
  {
      // 企业中心
    path: "/EnterpriseCenter",
    name: "EnterpriseCenter",
    redirect: '/EnterpriseCenter/Homes',
    component: resolve => require(['@/views/EnterpriseCenter.vue'], resolve),
    children: [
      {
        path: 'Homes',
        component: resolve => require(['@/components/EnterpriseChildren/Homes'], resolve),
      },    //企业主页
      {
        path: 'EnterpriseHome',
        component: resolve => require(['@/components/EnterpriseChildren/EnterpriseHome'], resolve),
      },    //投诉管理
      {
        path: 'CDetails',
        component: resolve => require(['@/components/EnterpriseChildren/CDetails'], resolve),
      },    //详情
      {
        path: 'CommentManagement',
        component: resolve => require(['@/components/EnterpriseChildren/CommentManagement'], resolve),
      },    //评论管理NoticeNotice
      {
        path: 'NoticeNotice',
        component: resolve => require(['@/components/EnterpriseChildren/NoticeNotice'], resolve),
      },    //通知公告
      {
        path: 'AccountManagement',
        component: resolve => require(['@/components/EnterpriseChildren/AccountManagement'], resolve),
      },    //账号管理
      {
        path: 'Help',
        component: resolve => require(['@/components/EnterpriseChildren/Help'], resolve),
      },    //帮助反馈
    ]
  },
  {
    path: "/Search",
    name: "Search",
    component: resolve => require(['@/views/Search.vue'], resolve),
    },
    {
      path: "/About",
      name: "About",
      component: resolve => require(['@/views/About.vue'], resolve),
      },
  {
    path: "/404",
    name: "404",
    component: resolve => require(['@/views/404.vue'], resolve),
    },
    {
    path: "*", // 此处需特别注意置于最底部
    redirect: "/404"
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
