import axios from 'axios'
import { Message,} from 'element-ui'
// create an axios instance
let needTokenArr = [
  {
    baseURL: 'https://tsadmin.qudong.com/api/',
    timeout: 50000 // request timeout
  },
  {
    baseURL: "https://tsadmin.qudong.com/api/",
    timeout: 50000 // request timeout
  }
]
// axios.defaults.baseURL = 'https://tsadmin.qudong.com/api/'

export default function(axiosPamars,tokenTF){
    //type就是判断接口是否要传token，接口中传true为不需要传token，如果需要传token则接口中不用传type参数
    let service
    if(process.env.NODE_ENV == 'development'){
      service = axios.create(needTokenArr[0])
    }else{
      service = axios.create(needTokenArr[1])
    }
  // export default function(){ 
  //   const service = axios.create({
  //   baseURL: window.api.base,
  //   timeout: 50000 // request timeout
  // })
  // request interceptor
  service.interceptors.request.use(
    config => {
      if (tokenTF && localStorage.getItem("token")) {
          //这里就是不需要加token请求头
      }else{
          config.headers.token = localStorage.getItem("token");;
      }
      // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      return config
    },
    error => {
      Promise.reject(error)
    }
  )
  service.interceptors.response.use(
    response => {
      const res = response
      if (res.data.code == 401) {
        Message({
          message: response.data.message,
          type: "error",
          duration: 5 * 1000
        });
        window.location.href="https://"+window.location.host;
        return Promise.reject(res)
      } 
      // else {
        return res
      // }
    },
    error => {
      Message({
        message: error.message,
        type: 'error',
      })
      return Promise.reject(error)
    }
  )

  return service(axiosPamars)
}