<template>
  <div class="BJS">
    <Header></Header>
    <!-- 图文 -->
    <div class="banner">
      <div class="banner_bx">
        <div class="banner_bx_left">
          <el-carousel trigger="click">
            <el-carousel-item v-for="(item,index) in tuwens" :key="index">
              <a :href="item.url" target="_blank">
                <img :src="item.img" alt="">
              <div class="Table_of_contents">
                <div class="Table_of_contents_title">{{item.title}}</div>
              </div>
              </a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="banner_bx_right">
          <div class="Headlines"><img src="../assets/img/toutiao.png" alt=""><a :href="C_List.url" target="_blank"><span>{{C_List.title}}</span></a>
          </div>
          <div class="Headlines_bottom" >
            <div class="Headlines_bottom_list" v-for="(item,index) in X_List.slice(0,4)" :key="index" >
              <a :href="item.url" class="Headlines_bottom_list_a" target="_blank">
              <div class="yuandian"></div>
              <div>{{item.title}}</div>
              </a>
            </div>
          </div>
          <div class="Exposure_table">
            <div class="Exposure_table_top">
              <img src="../assets/img/baoguangtai.png" alt="">
              <div class="Exposure_table_top_right" @click="GO_Dts(OneComplaintList.id)"><span>[{{OneComplaintList.business_name}}]</span><span>{{OneComplaintList.statement_title}}</span></div>
            </div>
            <div class="Exposure_table_bottom">
              <div class="Exposure_table_bottom_list" v-for="(item,index) in ComplaintList" :key="index"
                @click="Go_Details(item.id)"><img src="../assets/img/tousu_icon.png" alt="">
                <div>{{item.statement_title}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 图片 -->
    <div class="Advertisement" v-if="ZBimg.img!=''">
      <img :src="ZBimg.img" alt="" />
    </div>
    <div v-else style="height:20px;"></div>
    <!-- 左右两侧 -->
    <div class="Bottom_list">
      <div class="Bottom_list_bx">
        <MainLeft></MainLeft>
        <MainRight></MainRight>
      </div>
    </div>
    <div class="gotop" v-show="gotop" @click="toTop">回到顶部</div>
    <!-- 底部 -->
    <Footer></Footer>

  </div>
</template>

<script>
import Header from "../components/Header.vue";
import MainLeft from "../components/MainLeft.vue";
import MainRight from "../components/MainRight.vue";
import Footer from "../components/Footer.vue";
import Axios from "axios";
import md5 from "js-md5";
export default {
  inject:['reload'],
  name: "index",
  data() {
    return {
      List: [],
      actives: 0,
      tuwens: [],
      ComplaintList: [],
      C_List:[],
      OneComplaintList:'',
      X_List:[],
      C_List:'',    
      ZBimg:'',   //中部banner
      gotop: false,
    };
  },

  components: {
    Header,
    MainLeft,
    MainRight,
    Footer,
  },

  computed: {},

  methods: {
    // 滚动到顶部
    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 200;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 30);
    },
    GO_Dts(id){
        this.$router.push({ name: "Details", query: { id: id } });
    },
    Crequest() {
      let that = this;
      Axios({
        method: "get",
        url: "https://weixin.qudong.com/api/index/index",
       params: {
          // category: 823,
        },
      }).then((res) => {
        that.X_List = res.data.data;
        that.C_List=that.X_List[that.X_List.length-1];  // 拿数组最后一个
        //console.log(that.C_List)
        //console.log(that.X_List)
      });
    },
    Banner() {
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/banner",
        params: {
          type: 1,
        },
      }).then((res) => {
        //console.log(res.data.data);
        this.tuwens = res.data.data;
      });
    },
    CBanner() {
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/banner",
        params: {
          type: 3,
        },
      }).then((res) => {
        console.log(res);
        this.ZBimg = res.data.data[0];
        //console.log(this.ZBimg)
      });
    },
    // 曝光台投诉
    Complaint() {
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/complaint_index",
        params: {
          type: 2,
          limit:6,
          key: md5("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB"),
        },
      }).then((res) => {
        console.log(res.data.data);
        this.ComplaintList = res.data.data.data;
        this.OneComplaintList=this.ComplaintList[this.ComplaintList.length-1];
        //console.log(this.OneComplaintList)
      });
    },
    // 去详情
    Go_Details(id) {
      this.$router.push({ name: "Details", query: { id: id } });
    },
  },
  mounted() {
    this.Banner();
    this.Complaint();
    this.Crequest();    //曝光台标题
    this.CBanner();   //长图
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>


<style scoped>
.BJS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.banner {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}
.banner_bx {
  width: 1200px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.banner_bx_left {
  width: 49%;
  height: 100%;
  cursor: pointer;
}
.banner_bx_left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner_bx_right {
  width: 49%;
  height: 100%;
  background: #fff;
}
.Headlines {
  width: 90%;
  height: 45px;
  line-height: 45px;
  border: 1px solid #ccc;
  padding-left: 15px;
  margin-left: 20px;
  margin-top: 15px;
  font-size: 26px;
  font-weight: 550;
  position: relative;
  align-items: center;
  display: flex;
}
.Headlines span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Headlines img {
  width: 81px;
  height: 40px;
  margin-right: 20px;
}
.Headlines_bottom {
  width: 93%;
  height:70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 35px;
  padding-left: 10px;
  margin-left: 10px;
  margin-top: 7px;
  position: relative;
  flex-wrap: wrap;
}
.Headlines_bottom_list {
  width: 45%;
  cursor: pointer;
}
.Headlines_bottom_list_a{
  display: flex;
  align-items: center;
}
.Headlines_bottom_list div:nth-child(2) {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Headlines_bottom_list .yuandian {
  width: 7px;
  height: 7px;
  background: #1f72d9;
  border-radius: 50%;
  opacity: 0.7;
  margin-right: 5px;
}

.Exposure_table {
  margin-top: 20px;
}

.Exposure_table_top {
  width: 90%;
  display: flex;
  margin-left: 20px;
}
.Exposure_table_bottom {
  width: 93%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
  padding-left: 10px;
  margin-left: 10px;
  margin-top: 7px;
  position: relative;
  flex-wrap: wrap;
}
.Exposure_table_bottom_list {
  display: flex;
  align-items: center;
  width: 45%;
  cursor: pointer;
}
.Exposure_table_bottom_list img {
  margin-right: 15px;
}
.Exposure_table_bottom_list div:nth-child(2) {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Exposure_table_top img {
  /* margin-right: 20px; */
}
.Exposure_table_top_right {
  display: flex;
  cursor: pointer;
  border-bottom: 2px dashed black;
}
.Exposure_table_top_right span:nth-child(1) {
  color: #f90e0e;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  margin-left:15px;
}

.Exposure_table_top_right span:nth-child(2) {
  color: #333333;
  width: 310px;
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Advertisement {
  margin: 20px 0;
  width: 100%;
}
.Advertisement img {
  width: 1200px;
  height:80px;
  margin: 0 auto;
}
.Bottom_list {
  width: 100%;
}
.Bottom_list_bx {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  /* background: red; */
}
.Bottom_list_bx_left {
  width: 900px;
  background: #fff;
}
.Bottom_list_bx_right {
  width: 280px;
}
.czp {
  width: 96%;
  height: 50px;
  line-height: 50px;
  padding-left: 35px;
}
/* .swiper-button-prev {
  background-size: 25px 25px;
  font-size: 30px;
  background: #fff;
  width: 33px;
  height: 53px;
  left: -3px;
  top: 23px;
  color: #1f72d9;
} */
/* .swiper-button-next {
  background-size: 25px 25px;
  font-size: 30px;
  background: #fff;
  width: 33px;
  height: 53px;
  right: -2px;
  top: 23px;
  color: #1f72d9;
} */
.Display_list {
  width: 100%;
}
.Display_list_box {
  width: 100%;
  height: 180px;
  border-bottom: 1px solid #e8e8e8;
}
.Display_list_box_top:nth-child(1) {
  border-top: 1px solid #e5e5e5;
}
.Display_list_box_top {
  padding: 10px 20px 0 20px;
  display: flex;
  justify-content: space-between;
}
.Display_list_box_top_left {
  font-size: 20px;
}
.Display_list_box_top_right {
  background: #1f72d9;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fff;
}
.Display_list_box_top_right1 {
  background: orange;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fff;
}
.Display_list_box_center {
  padding: 0 20px;

  margin-top: 10px;
}
.Display_list_box_center_top {
  display: flex;
}
.Display_list_box_center_left {
  color: #1f72d9;
  width: 77px;
  margin-right: 5px;
}
.Display_list_box_center_right {
  width: 650px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Display_list_box_bottom {
  display: flex;
  padding: 0 20px;
  margin-top: 10px;
}
.Display_list_box_bottom_left {
  width: 50%;
  display: flex;
  align-items: center;
}

.Display_list_box_bottom_left img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}
.Display_list_box_bottom_left .name {
  margin-right: 30px;
}
.Display_list_box_bottom_left .data {
  color: #999999;
}
.Display_list_box_bottom_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Display_list_box_bottom_right img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-left: 30px;
}
.Display_list_box_bottom_right span {
}
.Bottom_list_bx_right .tousu_img {
  width: 100%;
}
.Bottom_list_bx_right .paihang_img {
  width: 100%;
  margin-top: 30px;
}
.zheshizidingyide {
  background: #fff;
  padding-bottom: 20px;
}
.paihangbang {
  border-top: 1px solid #ccc;
}
.paihang_list {
  padding: 10px 0;
  width: 85%;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8;
  display: flex;

  align-items: center;
}
>>> .van-tabs__line {
  height: 1px !important;
}
.paihang_list div:nth-child(1) {
  display: flex;
}
.paihangbang_left {
  display: flex;
  align-items: center;
}
.paihangbang_left_left {
  width: 23px;
  height: 29px;
}
.paihang_list .paoihang_icon {
  margin-left: 30px;
}
.paihang_list .paihang_num {
  width: 20px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  border-radius: 5px;
  color: #fff;
}
.paihangbang_right {
  margin-left: 20px;
}
.Hot_complaints {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  background: #fff;
}
.Hot_complaints .title {
  width: 100%;
  font-size: 18px;
  font-weight: 550;
  color: #1f72d9;
  padding: 15px 0;
  margin-left: 4%;
  margin-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
}

.Hot_complaints .Hot_List {
  width: 92%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Hot_complaints .Hot_List img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.Hot_List_title {
  width: 150px;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Hot_List_data {
  font-size: 14px;
  color: #999999;
  cursor: pointer;
}

.swiper-slide {
  cursor: pointer;
}
.swiper-slide:nth-child(1) {
  width: 60px !important;
}
.active {
  color: #2397ee;
}

/* 弹出 */
.Eject_Complaint_box {
  position: absolute;
  width: 100%;
  height: 100%;
}
.Eject_Complaint {
  width: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.item {
  height: 300px;
}
.img-count-in img {
  height: 300px;
}
.Table_of_contents {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 40px;
  background: url("../assets/img/jiaodianbj.png");
  background-repeat: repeat;
  background-size: 100%;
  opacity: 0.9;
}
.Table_of_contents_title {
  font-size: 18px;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  margin-left: 120px;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.swiper-button-next {
  top: 150px;
  right: 5px;
}
.swiper-button-prev {
  top: 150px;
  left: 5px;
}
.small {
  position: absolute;
  bottom: 0;
}
>>> .el-icon-arrow-left {
  font-size: 30px;
}
>>> .el-icon-arrow-right {
  font-size: 30px;
}
>>> .el-carousel__indicators {
  display: none;
}
>>> .el-carousel__item  img{
  object-fit:unset;
}
.gotop {
  text-align: center;
  position: fixed;
  right: 200px;
  bottom: 70px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  background: white;
  color: #000000;
}
</style>
