<template>
  <div>
    <div class="Footer">
      ©驱动中国 2005-2019 版权所有 京ICP备11013097号 广播电视节目许可证： (京)
      字第07618号 京公网安备:11010802028348号 高新企业认证编号： GR201811007312
      CMSTOP
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.Footer {
  width: 100%;
  height: 40px;
  margin:100px auto 0 auto;
  color: #fff;
  font-size: 14px;
  background: #242222;
  height: 44px;
  line-height: 44px;
  text-align: center;
}
</style>